<div class="manage-wrapper">
    <div class="manage-header">
        <div class="page-titles">
            <h2 class="title">Carrier Roles</h2>
            <p class="subtitle">Track, manage and forecast your customers and orders.</p>
        </div>
        <div class="actions">
            <button type="button" matRipple class="btn recycle-btn" appDebounceClick (debounceClick)="recycleBin()">
                <svg-icon src="assets/svg/bin.svg" alt="bin"></svg-icon>
                Deactivated
            </button>
            <button type="button" class="btn clear-btn" matRipple (click)="clearAll()">
                <svg-icon src="assets/svg/metla.svg" alt="bin"></svg-icon>
                Clear tables
            </button>
        </div>
    </div>
    <div class="manage-content">

        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-blue"></div>
                    <h3 class="column-title">Carriers</h3>
                    <div class="count-block">{{ allCarriers.length }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block" [ngClass]="{ 'disabled-input': isCarrierSearchDisabled()}">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" [disabled]="isCarrierSearchDisabled()" placeholder="Search" type="text" [(ngModel)]="searchCarrierKey" (input)="filterCarriersByKey()" />
                    </div> 
                    <div class="add" matRipple (click)="openCreateCarrierPopup()">
                        <svg-icon class="add-icon" src="assets/svg/plus.svg" alt="add"></svg-icon>
                    </div>
                </div>
            </div>

            <div class="hr-line blue-line"></div>

            @defer (when carriers.length > 0) {
                @if(carriers.length === 0) {
                    <div class="empty-rec">
                        <svg-icon src="assets/svg/search-faded-icon.svg"></svg-icon>
                        <p>No Carriers found</p>
                        <span>No carriers was found but you can create one</span>
                      </div>
                } @else {
                    <div class="list">
                        <div class="list-header">
                            <input type="checkbox" id="checkbox-carriers" class="custom-checkbox minus" [checked]="carriersChecked" (click)="$event.stopPropagation(); checkCarriers()">
                            <label for="checkbox-carriers"></label>
                            <p class="list-header-text name">Name</p>
                        </div>
        
                        <div class="row" *ngFor="let carrier of carriers; let i = index" [ngClass]="{ 'selected': carrier.selected }" (click)="selectCarrier(carrier)">
                            <input type="checkbox" [id]="'checkbox-carrier' + i" class="custom-checkbox" [checked]="carrier.checked" (click)="$event.stopPropagation(); checkCarrier(carrier)" />
                            <label [for]="'checkbox-carrier' + i" (click)="$event.stopPropagation()"></label>
                            <p class="name">{{ carrier.name }}</p>
                            <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                <ng-template #editTooltipContent>
                                    <ul class="manage-edit-tooltip">
                                        <li (click)="editCarrier(carrier)" matRipple>
                                            <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                            <p>Edit</p>
                                        </li>
                                        <li (click)="carrierDeactivateHandler(carrier)" matRipple>
                                            <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                            <p>Delete</p>
                                        </li>
                                    </ul>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                }
            }
        </div>

        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-yellow"></div>
                    <h3 class="column-title">Drivers</h3>
                    <div class="count-block">{{ allDrivers.length }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block" [ngClass]="{ 'disabled-input': isDriversSearchDisabled()}">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" [disabled]="isDriversSearchDisabled()" placeholder="Search" type="text" [(ngModel)]="searchDriverKey" (input)="filterDriversByKey()" />
                    </div> 
                    <div class="add" matRipple (click)="addDriver()">
                        <svg-icon class="add-icon" src="assets/svg/plus.svg" alt="add"></svg-icon>
                    </div>
                </div>
            </div>

            <div class="hr-line yellow-line"></div>
            @defer (when drivers.length > 0) {
                <div class="list" [ngClass]="{ 'users-bloks': driversDisplay === 'both' || drivers.length === 0 }">
                    @if (driversDisplay === 'both') {
                        @if(driverBlocks.length === 0) {
                            <div class="empty-rec">
                                <svg-icon src="assets/svg/search-faded-icon.svg"></svg-icon>
                                <p>No Drivers found</p>
                                <span>No drivers was found but you can create one</span>
                            </div>
                        } @else {
                            <div class="block" *ngFor="let block of driverBlocks; let blockIdx = index">
                                <div class="carrier-dispatcher">
                                    <div class="users-check-block">
                                        <img src="assets/svg/users-check.svg" alt="">
                                    </div>
                                    <div class="tied-users">
                                        <div class="tied-user">
                                            <h4 class="supervisor">Carrier: </h4>
                                            <h4 class="supervisor-name">{{ block.carrierName }}</h4>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="list-header">
                                    <input type="checkbox" id="checkbox-drivers" class="custom-checkbox minus" [checked]="driversChecked" (click)="$event.stopPropagation(); checkDrivers()">
                                    <label for="checkbox-drivers"></label>
                                    <p class="list-header-text name">Name</p>
                                </div>
                                
                                <div class="row" *ngFor="let driver of block.array; let driverIdx = index" [ngClass]="{ 'selected': driver.selected }" (click)="deSelectDriver(driver)">
                                    <input type="checkbox" [id]="'checkbox-dispatcher-block' + blockIdx + driverIdx" class="custom-checkbox" [checked]="driver.checked" (click)="$event.stopPropagation(); checkDriver(driver)" />
                                    <label [for]="'checkbox-dispatcher-block' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                    @if(driver.coDriverId && driver.coDriverFullName) {
                                        <p class="name">{{driver.driverFullName+','}} <span class="codriver-name">{{'+'+driver.coDriverFullName}}</span></p>
                                    } @else {
                                        <p class="name">{{ driver.driverFullName }}</p>
                                    }
                                    <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                        <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                        <ng-template #editTooltipContent>
                                            <ul class="manage-edit-tooltip">
                                                <li (click)="editDriverClick(driver)" matRipple>
                                                    <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                    <p>Edit</p>
                                                </li>
                                                <li (click)="deleteDriverClick(driver)" matRipple>
                                                    <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                    <p>Delete</p>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            @if(remainingDrivers.length > 0) {
                                <div class="block">
                                    <div class="list-header">
                                        <input type="checkbox" id="checkbox-drivers" class="custom-checkbox minus" [checked]="driversChecked" (click)="$event.stopPropagation(); checkDrivers()">
                                        <label for="checkbox-drivers"></label>
                                        <p class="list-header-text name">Name</p>
                                        
                                    </div>
                                    <div class="row" *ngFor="let driver of remainingDrivers; let i = index" [ngClass]="{ 'selected': driver.selected }" (click)="selectDriver(driver)">
                                        <input type="checkbox" [id]="'checkbox-driver' + i" class="custom-checkbox" [checked]="driver.checked" (click)="$event.stopPropagation(); checkDriver(driver)" />
                                        <label [for]="'checkbox-driver' + i" (click)="$event.stopPropagation()"></label>
                                        @if(driver.coDriverId && driver.coDriverFullName) {
                                            <p class="name">{{driver.driverFullName+','}} <span class="codriver-name">{{'+'+driver.coDriverFullName}}</span></p>
                                        } @else {
                                            <p class="name">{{ driver.driverFullName }}</p>
                                        }
                                        <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                            <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                            <ng-template #editTooltipContent>
                                                <ul class="manage-edit-tooltip">
                                                    <li (click)="editDriverClick(driver)" matRipple>
                                                        <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                        <p>Edit</p>
                                                    </li>
                                                    <li (click)="deleteDriverClick(driver, ['Driver'])" matRipple>
                                                        <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                        <p>Delete</p>
                                                    </li>
                                                </ul>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            }
                        }
                    }
                
                    @if(driversDisplay === 'list') {
                        @if(drivers.length === 0) {
                            <div class="empty-rec">
                                <svg-icon src="assets/svg/search-faded-icon.svg"></svg-icon>
                                <p>No Drivers found</p>
                                <span>No drivers was found but you can create one</span>
                            </div>
                        } @else {
                            <div class="list-header">
                                <input type="checkbox" id="checkbox-drivers" class="custom-checkbox minus" [checked]="driversChecked" (click)="$event.stopPropagation(); checkDrivers()">
                                <label for="checkbox-drivers"></label>
                                <p class="list-header-text name">Name</p>
                            </div>
                            <div class="row" *ngFor="let driver of drivers; let i = index" [ngClass]="{ 'selected': driver.selected }" (click)="selectDriver(driver)">
                                <input type="checkbox" [id]="'checkbox-driver' + i" class="custom-checkbox" [checked]="driver.checked" (click)="$event.stopPropagation(); checkDriver(driver)" />
                                <label [for]="'checkbox-driver' + i" (click)="$event.stopPropagation()"></label>
                                @if(driver.coDriverId && driver.coDriverFullName) {
                                    <p class="name">{{driver.driverFullName+','}} <span class="codriver-name">{{'+'+driver.coDriverFullName}}</span></p>
                                } @else {
                                    <p class="name">{{ driver.driverFullName }}</p>
                                }
                                <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                    <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                    <ng-template #editTooltipContent>
                                        <ul class="manage-edit-tooltip">
                                            <li (click)="editDriverClick(driver)" matRipple>
                                                <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                <p>Edit</p>
                                            </li>
                                            <li (click)="deleteDriverClick(driver, ['Driver'])" matRipple 
                                                [ngClass]="{ 'disabled-btn': !isDispatchAdminRole()}"
                                                matRipple
                                                [matRippleDisabled]="!isDispatchAdminRole()"
                                            >
                                                <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                <p>Delete</p>
                                            </li>
                                        </ul>
                                    </ng-template>
                                </div>
                            </div>
                        }
                    }
                </div>
            }
        </div>

        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-green"></div>
                    <h3 class="column-title">Equipment</h3>
                    <div class="count-block">{{ allDrivers.length*2 }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block" [ngClass]="{ 'disabled-input': isEqSearchDisabled()}">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" [disabled]="isEqSearchDisabled()" placeholder="Search" type="text" [(ngModel)]="searchEqKey" (input)="searchEqByKey()" />
                    </div> 
                    <div class="add" matRipple (click)="toggleOverlay()">
                        <svg-icon class="add-icon" src="assets/svg/plus.svg" alt="add"></svg-icon>
                      </div>
                      
                      <div class="overlay-container"  >
                        <ul class="manage-edit-tooltip eq-tooltip" [@overlayAnimation]="isOverlayVisible ? 'visible' : 'hidden'" >
                          <li (click)="createVehicleClick()" matRipple>
                            <svg-icon class="edit-icon" src="assets/svg/vehicle-icon.svg" alt="edit-vehicle"></svg-icon>
                            <p>Create vehicle</p>
                          </li>
                          <li (click)="createTrailerClick()" matRipple>
                            <svg-icon class="delete-icon" src="assets/svg/trailer-icon.svg" alt="edit-trailer"></svg-icon>
                            <p>Create trailer</p>
                          </li>
                        </ul>
                      </div>
                      
                </div>
            </div>

            <div class="hr-line green-line"></div>
            @defer (when equipmentBlocks.length > 0) {
                <div class="list eq-list">
                    @if(equipmentBlocks.length === 0) {
                        <div class="empty-rec">
                            <svg-icon src="assets/svg/search-faded-icon.svg"></svg-icon>
                            <p>No Equipment found</p>
                            <span>No equipment was found but you can create one</span>
                        </div>   
                    } @else {
                        <div class="block" *ngFor="let block of equipmentBlocks; let blockIdx = index" style="background-color: #fff">
                            <div class="carrier-dispatcher">
                                <div class="users-check-block">
                                    <img src="assets/svg/users-check.svg" alt="">
                                </div>
                                <div class="tied-users">
                                    <div class="tied-user">
                                        <h4 class="supervisor">Carrier: </h4>
                                        <h4 class="supervisor-name">{{ block.carrierName }}</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="list-header">
                                <input type="checkbox" [id]="'checkbox-equipment-block' + blockIdx" class="custom-checkbox minus" [checked]="block.checked" (click)="$event.stopPropagation(); checkEquipmentBlock(block)">
                                <label [for]="'checkbox-equipment-block' + blockIdx"></label>
                                @if(block.unnasigned) {
                                    <p class="list-header-text name un-eq-title">Unassigned Equipment</p>
                                } @else {
                                    <p class="list-header-text name">Driver name</p>
                                }
                            </div>
                            
                            <div class="eq-row" *ngFor="let eq of block.array; let driverIdx = index" [ngClass]="{ 'selected': eq.selected }" (click)="selectEquipment(eq)">
                                <div class="info">
                                    @if(!block.unnasigned) {
                                        <p>Driver : {{ eq.driverName }}</p>
                                        <div class="equipment">
                                            <div class="eq">
                                                <input type="checkbox" [id]="'checkbox-eq-trailer' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.trailerChecked" (click)="$event.stopPropagation(); checkEqTrailer(block, eq)" />
                                                <label [for]="'checkbox-eq-trailer' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                                <div class="eq-title">
                                                    <span>Trailer: {{ eq.trailerUnit }}
                                                        @if(eq.trailerUnit) {
                                                            <div class="edit eq-edit" [appTooltip]="editTooltipContent" [appTooltipPos]="eqEditTooltipPos">
                                                                <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                                                <ng-template #editTooltipContent>
                                                                    <ul class="manage-edit-tooltip">
                                                                        <li (click)="editTrailerClick(eq)" matRipple>
                                                                            <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                                            <p>Edit</p>
                                                                        </li>
                                                                        <li (click)="deleteTrailerHandler(eq)" matRipple>
                                                                            <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                                            <p>Delete</p>
                                                                        </li>
                                                                    </ul>
                                                                </ng-template>
                                                            </div>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="eq">
                                                <input type="checkbox" [id]="'checkbox-eq-vehicle' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.vehicleChecked" (click)="$event.stopPropagation(); checkEqVehicle(block, eq)" />
                                                <label [for]="'checkbox-eq-vehicle' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                                <div class="eq-title">
                                                    <span>Vehicle: {{ eq.vehicleUnit }}
                                                        @if(eq.vehicleUnit) {
                                                            <div class="edit eq-edit" [appTooltip]="editTooltipContent" [appTooltipPos]="eqEditTooltipPos">
                                                                <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                                                <ng-template #editTooltipContent>
                                                                    <ul class="manage-edit-tooltip">
                                                                        <li (click)="editVehicleClick(eq)" matRipple>
                                                                            <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                                            <p>Edit</p>
                                                                        </li>
                                                                        <li (click)="deleteVehicleHandler(eq)" matRipple>
                                                                            <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                                            <p>Delete</p>
                                                                        </li>
                                                                    </ul>
                                                                </ng-template>
                                                            </div>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    } @else {
                                        <div class="equipment">
                                            @if(eq.trailerId && eq.trailerUnit) {
                                                <div class="eq">
                                                    <input type="checkbox" [id]="'checkbox-eq-trailer' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.trailerChecked" (click)="$event.stopPropagation(); checkEqTrailer(block, eq)" />
                                                    <label [for]="'checkbox-eq-trailer' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                                    <div class="eq-title">
                                                        <span>Trailer: {{ eq.trailerUnit }}
                                                            @if(eq.trailerUnit) {
                                                                <div class="edit eq-edit" [appTooltip]="editTooltipContent" [appTooltipPos]="eqEditTooltipPos">
                                                                    <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                                                    <ng-template #editTooltipContent>
                                                                        <ul class="manage-edit-tooltip">
                                                                            <li (click)="editTrailerClick(eq)" matRipple>
                                                                                <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                                                <p>Edit</p>
                                                                            </li>
                                                                            <li (click)="deleteTrailerHandler(eq)" matRipple>
                                                                                <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                                                <p>Delete</p>
                                                                            </li>
                                                                        </ul>
                                                                    </ng-template>
                                                                </div>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            @if(eq.vehicleId && eq.vehicleUnit) {
                                                <div class="eq">
                                                    <input type="checkbox" [id]="'checkbox-eq-vehicle' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.vehicleChecked" (click)="$event.stopPropagation(); checkEqVehicle(block, eq)" />
                                                    <label [for]="'checkbox-eq-vehicle' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                                    <div class="eq-title">
                                                        <span>Vehicle: {{ eq.vehicleUnit }}
                                                            @if(eq.vehicleUnit) {
                                                                <div class="edit eq-edit" [appTooltip]="editTooltipContent" [appTooltipPos]="eqEditTooltipPos">
                                                                    <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                                                    <ng-template #editTooltipContent>
                                                                        <ul class="manage-edit-tooltip">
                                                                            <li (click)="editVehicleClick(eq)" matRipple>
                                                                                <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                                                <p>Edit</p>
                                                                            </li>
                                                                            <li (click)="deleteVehicleHandler(eq)" matRipple>
                                                                                <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                                                <p>Delete</p>
                                                                            </li>
                                                                        </ul>
                                                                    </ng-template>
                                                                </div>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="block" *ngFor="let block of remainingEqBlocks; let blockIdx = index" style="background-color: #fff">
                            <div class="carrier-dispatcher">
                                <div class="users-check-block">
                                    <img src="assets/svg/users-check.svg" alt="">
                                </div>
                                <div class="tied-users">
                                    <div class="tied-user">
                                        <h4 class="supervisor">Carrier: </h4>
                                        <h4 class="supervisor-name">{{ block.carrierName }}</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="list-header">
                                <input type="checkbox" [id]="'checkbox-equipment-block' + blockIdx" class="custom-checkbox minus" [checked]="block.checked" (click)="$event.stopPropagation(); checkEquipmentBlock(block)">
                                <label [for]="'checkbox-equipment-block' + blockIdx"></label>
                                <p class="list-header-text name">Driver name</p>
                            </div>
                            
                            <div class="eq-row" *ngFor="let eq of block.array; let driverIdx = index" [ngClass]="{ 'selected': eq.selected }" (click)="deSelectEq(eq)">
                                <div class="info">
                                    <p>Driver : {{ eq.driverName }}</p>
                                    <div class="equipment">
                                        <div class="eq">
                                            <input type="checkbox" [id]="'checkbox-eq-trailer' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.trailerChecked" (click)="$event.stopPropagation(); checkEqTrailer(block, eq)" />
                                            <label [for]="'checkbox-eq-trailer' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                            <div class="eq-title">
                                                <span>Trailer: {{ eq.trailerUnit }}
                                                    @if(eq.trailerUnit) {
                                                        <div class="edit eq-edit" [appTooltip]="editTooltipContent" [appTooltipPos]="eqEditTooltipPos">
                                                            <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                                            <ng-template #editTooltipContent>
                                                                <ul class="manage-edit-tooltip">
                                                                    <li (click)="editTrailerClick(eq)" matRipple>
                                                                        <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                                        <p>Edit</p>
                                                                    </li>
                                                                    <li (click)="deleteTrailerHandler(eq)" matRipple>
                                                                        <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                                        <p>Delete</p>
                                                                    </li>
                                                                </ul>
                                                            </ng-template>
                                                        </div>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div class="eq">
                                            <input type="checkbox" [id]="'checkbox-eq-vehicle' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.vehicleChecked" (click)="$event.stopPropagation(); checkEqVehicle(block, eq)" />
                                            <label [for]="'checkbox-eq-vehicle' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                            <div class="eq-title">
                                                <span>Vehicle: {{ eq.vehicleUnit }}
                                                    @if(eq.vehicleUnit) {
                                                        <div class="edit eq-edit" [appTooltip]="editTooltipContent" [appTooltipPos]="eqEditTooltipPos">
                                                            <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                                            <ng-template #editTooltipContent>
                                                                <ul class="manage-edit-tooltip">
                                                                    <li (click)="editVehicleClick(eq)" matRipple>
                                                                        <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                                        <p>Edit</p>
                                                                    </li>
                                                                    <li (click)="deleteVehicleHandler(eq)" matRipple>
                                                                        <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                                        <p>Delete</p>
                                                                    </li>
                                                                </ul>
                                                            </ng-template>
                                                        </div>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- //--------------------------------------------------unsignedEqBlocks-----------------------------------------------// -->
                        <div class="block" *ngFor="let block of unsignedEqBlocks; let blockIdx = index" style="background-color: #fff">
                            <div class="carrier-dispatcher">
                                <div class="users-check-block">
                                    <img src="assets/svg/users-check.svg" alt="">
                                </div>
                                <div class="tied-users">
                                    <div class="tied-user">
                                        <h4 class="supervisor">Carrier: </h4>
                                        <h4 class="supervisor-name">{{ block.carrierName }}</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="list-header">
                                <input type="checkbox" [id]="'checkbox-equipment-block' + blockIdx" class="custom-checkbox minus" [checked]="block.checked" (click)="$event.stopPropagation(); checkEquipmentBlock(block)">
                                <label [for]="'checkbox-equipment-block' + blockIdx"></label>
                                <p class="list-header-text name un-eq-title">Unassigned Equipment</p>
                            </div>
                            
                            <div class="eq-row" *ngFor="let eq of block.array; let driverIdx = index" [ngClass]="{ 'selected': eq.selected }" (click)="selectEquipment(eq)">
                                <div class="info">
                                    <div class="equipment">
                                        @if(eq.trailerId && eq.trailerUnit) {
                                            <div class="eq">
                                                <input type="checkbox" [id]="'checkbox-eq-trailer' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.trailerChecked" (click)="$event.stopPropagation(); checkEqTrailer(block, eq)" />
                                                <label [for]="'checkbox-eq-trailer' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                                <div class="eq-title">
                                                    <span>Trailer: {{ eq.trailerUnit }}
                                                        @if(eq.trailerUnit) {
                                                            <div class="edit eq-edit" [appTooltip]="editTooltipContent" [appTooltipPos]="eqEditTooltipPos">
                                                                <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                                                <ng-template #editTooltipContent>
                                                                    <ul class="manage-edit-tooltip">
                                                                        <li (click)="editTrailerClick(eq)" matRipple>
                                                                            <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                                            <p>Edit</p>
                                                                        </li>
                                                                        <li (click)="deleteTrailerHandler(eq)" matRipple>
                                                                            <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                                            <p>Delete</p>
                                                                        </li>
                                                                    </ul>
                                                                </ng-template>
                                                            </div>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        @if(eq.vehicleId && eq.vehicleUnit) {
                                            <div class="eq">
                                                <input type="checkbox" [id]="'checkbox-eq-vehicle' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.vehicleChecked" (click)="$event.stopPropagation(); checkEqVehicle(block, eq)" />
                                                <label [for]="'checkbox-eq-vehicle' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                                <div class="eq-title">
                                                    <span>Vehicle: {{ eq.vehicleUnit }}
                                                        @if(eq.vehicleUnit) {
                                                            <div class="edit eq-edit" [appTooltip]="editTooltipContent" [appTooltipPos]="eqEditTooltipPos">
                                                                <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                                                <ng-template #editTooltipContent>
                                                                    <ul class="manage-edit-tooltip">
                                                                        <li (click)="editVehicleClick(eq)" matRipple>
                                                                            <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                                            <p>Edit</p>
                                                                        </li>
                                                                        <li (click)="deleteVehicleHandler(eq)" matRipple>
                                                                            <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                                            <p>Delete</p>
                                                                        </li>
                                                                    </ul>
                                                                </ng-template>
                                                            </div>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>