import { Component, OnDestroy, OnInit } from '@angular/core';

import * as Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { LoadsService } from '../../../../services/loads/loads.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  constructor(
    private loadsPageService: LoadsService,
  ) { }

  ngOnInit(): void {
    this.loadsPageService.connectWebSocket();
  }

  ngOnDestroy(): void {
    this.loadsPageService.disconnectWebSocket();
  }

}