import { Component, OnInit } from '@angular/core';
import { TrailersService } from './services/trailers/trailers.service';
import { firstValueFrom, forkJoin, Subscription } from 'rxjs';
import { VehiclesService } from './services/vehicles/vehicles.service';
import { CarriersService } from './services/carriers/carriers.service';
import { DialogService } from '../../../../../services/dialog/dialog.service';
import { DuplicatedDriversComponent } from './components/duplicated-drivers/duplicated-drivers.component';
import { ReassignEquipmentComponent } from './components/reassign-equipment/reassign-equipment.component';
import { ReassignedEquipmentComponent } from './components/reassigned-equipment/reassigned-equipment.component';
import { ReassignDriverComponent } from './components/reassign-driver/reassign-driver.component';
import { ReassignedDriverComponent } from './components/reassigned-driver/reassigned-driver.component';
import { DuplicateDriversComponent } from './components/duplicate-drivers/duplicate-drivers.component';
import { LoaderService } from '../../../../../services/loader/loader.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { UserRolesCode } from '../../../../../enum/user-role.enum';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EditUserPopupComponent } from '../../users/components/edit-user-popup/edit-user-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadsService } from '../../../../../services/loads/loads.service';
import { NotificationService } from '../../../../../services/notification.service';
import { DialogComponent } from '../../../../../components/dialog/dialog.component';
import { UsersPageService } from '../../../services/users/users.service';
import { UsersService } from '../dispatch/services/users/users.service';
import { RecycleBinComponent } from '../dispatch/components/recycle-bin/recycle-bin.component';
import moment from 'moment';
import { EditTrailerPopupComponent } from '../../../edit-trailer-popup/edit-trailer-popup.component';
import { EditVehiclePopupComponent } from '../../../edit-vehicle-popup/edit-vehicle-popup.component';
import { Item } from '../../../../../interfaces/item';
import { DataService } from '../../../../../services/reference-data/reference-data.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Driver } from '../dispatch/interfaces/driver';


interface DriverInfo {
  id: string
  driverFullName: string
  checked: boolean
  selected: boolean
  DriverId?: string
  carrierId: string
}

interface DriverBlock {
  carrierId: string
  carrierName: string
  array: DriverInfo[]
}

interface Equipment {
  driverId: string
  driverName: string
  carrierId: string
  carrierName: string
  trailerId: string
  trailerUnit: string
  vehicleUnit: string
  vehicleId: string
  trailerChecked: boolean
  vehicleChecked: boolean
  selected: boolean
  checked: boolean
}

interface EquipmentBlock {
  carrierId: string
  carrierName: string
  checked: boolean,
  unnasigned?: boolean,
  array: Equipment[]
}

@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.scss', '../../../main.module.styles.scss'],
  animations: [
    trigger('overlayAnimation', [
      state(
        'hidden',
        style({
          opacity: 0,
          transform: 'scale(0.9)',
          display: 'none',
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
          transform: 'scale(1)',
          display: 'block', 
        })
      ),
      transition('hidden => visible', [
        style({ display: 'block' }),
        animate('100ms ease-in'),
      ]),
      transition('visible => hidden', [
        animate('100ms ease-out', style({ opacity: 0, transform: 'scale(0.9)' })),
        style({ display: 'none' }),
      ]),
    ]),
  ]
})

export class CarrierComponent implements OnInit {

  constructor(
    private trailersService: TrailersService,
    private vehiclesService: VehiclesService,
    private carriersService: CarriersService,
    private dialog: DialogService,
    public dialog1: MatDialog,
    private loaderService: LoaderService,
    private jwtHelper: JwtHelperService,
    private loadsService: LoadsService,
    private notificationService: NotificationService,
    private usersPageService: UsersPageService,
    private usersService: UsersService,
    private dataService: DataService,
  ) {}

  allCarriers: any[] = []
  allDrivers: any[] = []
  allDriverBlocks: DriverBlock[] = []
  allEquipment: Equipment[] = []
  allUnEquipment: Equipment[] = []
  allEquipmentBlocks: EquipmentBlock[] = []
  allUnEquipmentBlocks: EquipmentBlock[] = []

  public trailerTypeSource: Array<Item> = new Array<Item>();
  public fuelTypeSource: Array<Item> = new Array<Item>();
  public ownerTypeSource: Array<Item> = new Array<Item>();
  public makeTypeSource: Array<Item> = new Array<Item>();
  private itemsSubscription: Subscription = new Subscription();
 
  carriers: any[] = []
  drivers: any[] = []
  trailers: any[] = []
  vehicles: any[] = []
 
  driverBlocks: DriverBlock[] = []
  equipmentBlocks: EquipmentBlock[] = []
  remainingEqBlocks: EquipmentBlock[] = []
  unsignedEqBlocks: EquipmentBlock[] = []
  remainingDrivers: any[] = []

  searchCarrierKey = ''
  searchDriverKey = ''
  searchEqKey = ''

  driversDisplay: 'list' | 'blocks' | 'both' = 'list'
  
  carriersChecked = false
  driversChecked = false
  equipmentChecked = false
  editTooltipPos: ConnectedPosition[] = [
    {
      originX: 'end',      // End (right) of the host element
      overlayX: 'end',   // Start (left) of the overlay
      originY: 'center',   // Bottom of the host element
      overlayY: 'top',     // Top of the overlay
      offsetX: 10,
      offsetY: 6
    }
  ]

  isOverlayVisible = false;
  eqEditTooltipPos: ConnectedPosition[] = [
    {
      originX: 'end',      // End (right) of the host element
      overlayX: 'end',   // Start (left) of the overlay
      originY: 'center',   // Bottom of the host element
      overlayY: 'top',     // Top of the overlay
      offsetX: 0,
      offsetY: 1
    }
  ]

  ngOnInit(): void {
    this.getData();
    this.startItemsObserver();
  }

  async getData() {
    this.loaderService.show();
    const trailers = this.trailersService.getTrailers()
    const vehicles = this.vehiclesService.getVehicles()
    const drivers = this.vehiclesService.getDrivers()
    const carriers = this.carriersService.getCarriers()

    forkJoin([trailers, vehicles, drivers, carriers]).subscribe({
      next: ([trailers, vehicles, drivers, carriers]) => {
        this.equipmentBlocks.length = this.allEquipment.length = this.unsignedEqBlocks.length = this.allUnEquipment.length = this.allUnEquipmentBlocks.length = 0;
        this.clearAll();
        this.carriers = carriers;
        this.drivers = drivers;
        this.trailers = trailers;
        this.vehicles = vehicles;
    
        [...carriers, ...drivers, ...trailers, ...vehicles].forEach(item => {
          item.selected = false;
          item.checked = false;
        });
    
        this.trailers.forEach(trailer => {
          const driver = this.drivers.find(driver => driver.trailerId === trailer.id);

          if (driver) {
            trailer.DriverId = driver.id;
            trailer.DriverName = driver.driverFullName;
          }
        });
    
        this.vehicles.forEach(vehicle => {
          const driver = this.drivers.find(driver => driver.vehicleId === vehicle.id);
          if (driver) {
            vehicle.DriverId = driver.id;
            vehicle.DriverName = driver.driverFullName;
          }
        });

        this.drivers.forEach(driver => {
          const trailerUnit = this.trailers.find(trailer => trailer.id === driver.trailerId)?.trailerUnit;
          const vehicleUnit = this.vehicles.find(vehicle => vehicle.id === driver.vehicleId)?.vehicleUnit;

          this.allEquipment.push({
            driverId: driver.id,
            driverName: driver.driverFullName,
            carrierId: driver.carrierId,
            carrierName: driver.carrierName,
            trailerId: driver.trailerId,
            trailerUnit: trailerUnit,
            vehicleUnit: vehicleUnit,
            vehicleId: driver.vehicleId,
            trailerChecked: false,
            vehicleChecked: false,
            selected: false,
            checked: false
          });
        });
        const unTrailerUnit: any = this.trailers.filter(f=> !this.drivers.some(s=> s.trailerId === f.id));
        const unVehicleUnit: any = this.vehicles.filter(f=> !this.drivers.some(s=> s.vehicleId === f.id));

        for (let index = 0; index < unTrailerUnit.length; index++) {
          const trailer = unTrailerUnit[index];
          this.allUnEquipment.push({
            driverId: '',
            driverName: '',
            carrierId: trailer.carrierId,
            carrierName: carriers.find((f:any)=>f.id === trailer.carrierId)?.name || '',
            trailerId: trailer.id,
            trailerUnit: trailer.trailerUnit,
            vehicleUnit: '',
            vehicleId: '',
            trailerChecked: false,
            vehicleChecked: false,
            selected: false,
            checked: false
          });
        }
        for (let index = 0; index < unVehicleUnit.length; index++) {
          const unVehicle = unVehicleUnit[index];
          this.allUnEquipment.push({
            driverId: '',
            driverName: '',
            carrierId: unVehicle.carrierId,
            carrierName: carriers.find((f:any)=>f.id === unVehicle.carrierId)?.name || '',
            trailerId: '',
            trailerUnit: '',
            vehicleUnit: unVehicle.vehicleUnit,
            vehicleId: unVehicle.id,
            trailerChecked: false,
            vehicleChecked: false,
            selected: false,
            checked: false
          });
        }

        this.unsignedEqBlocks = this.getEquipmentBlocks(this.allUnEquipment);             
        this.equipmentBlocks = this.getEquipmentBlocks(this.allEquipment);        
        this.allCarriers = structuredClone(carriers);
        this.carriers = structuredClone(carriers);
        this.allDrivers = structuredClone(drivers);
        this.allEquipmentBlocks = structuredClone(this.equipmentBlocks);
        this.allUnEquipmentBlocks = structuredClone(this.unsignedEqBlocks);
        this.allDriverBlocks = this.getDriverBlocks(structuredClone(this.allCarriers), structuredClone(this.allDrivers));
      },
      error: (err) => {
        this.loaderService.hide();
      },
      complete: () => {
        this.loaderService.hide();      
      }
    });
  }
  
  startItemsObserver() {
    this.itemsSubscription = this.dataService.items$.subscribe((items) => {
      items.map((item) => {
        switch (item.type) {
          case 'TrailerType':
            this.trailerTypeSource.push(item);
            break;
          case 'Ownership':
            this.ownerTypeSource.push(item);
            break;
          case 'Fuel':
            this.fuelTypeSource.push(item);
            break;
          case 'Make':
            this.makeTypeSource.push(item);
            break;
        }
      });
    });
  }

  public getDriversCount(): number {
    let count = 0
    if (this.driversDisplay === 'list') {
      count = this.drivers.length
    } else if (this.driversDisplay === 'both') {
      const blocked = this.driverBlocks.flatMap(block => block.array).length
      count = blocked + this.drivers.length
    }
    return count
  }

  public getEquipmentCount(): number {
    return this.equipmentBlocks.flatMap(block => block.array).length
  }

  isDispatchAdminRole(): boolean {
    const token = localStorage.getItem('token');
    const decodedToken: any = this.decodeToken(token);
    if(!decodedToken) return false;
    else {
      const isAdminRole: boolean = decodedToken.role.join() === UserRolesCode.ADMIN || decodedToken.role.join() === UserRolesCode.DISPATCHADMIN;
      if (isAdminRole) return true;
      else return false;
    }
  }

  toggleOverlay() {
    this.isOverlayVisible = !this.isOverlayVisible;
  }

  decodeToken(token: string | null): string | null {
    if (!token) {
      return null; // Token doesn't exist
    }
    try {
      // Decode the token
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken;
    } catch (error) {
      console.error('Failed to decode token', error);
      return null; // Invalid token
    }
  }

  selectCarrier(carrier: any): void {
    carrier.selected = !carrier.selected
    const selectedCarriers = this.carriers.filter(carrier => carrier.selected)
    if (selectedCarriers.length > 1) {
      this.driversDisplay = 'both';
      this.driverBlocks = this.getDriverBlocks(selectedCarriers, structuredClone(this.allDrivers))
      this.driverBlocks.forEach(block => block.array.forEach(d => d.selected = true))
      this.drivers = []
      this.getRemainingDriver();
      this.equipmentBlocks = this.allEquipmentBlocks.filter(block => selectedCarriers.some(c => c.id === block.carrierId))
      this.equipmentBlocks.forEach(b => b.array.forEach(eq => eq.selected = true))
      this.sortBySelectedUnEq(selectedCarriers);
      this.unsignedEqBlocks.forEach(b => b.array.forEach(eq => eq.selected = false))
      this.getRemainingEq();
    } else if (selectedCarriers.length === 1) {
      this.driversDisplay = 'list'
      this.drivers = this.allDrivers.filter(d => d.carrierId === selectedCarriers[0].id)
      this.drivers.forEach(d => d.selected = true)
      if(this.drivers.length > 1) {
        this.driversDisplay = 'both';
        this.driverBlocks = this.getDriverBlocks(selectedCarriers, structuredClone(this.allDrivers))
        this.driverBlocks.forEach(block => block.array.forEach(d => d.selected = true))
        this.drivers = []
        this.getRemainingDriver();
      }
      this.equipmentBlocks = this.allEquipmentBlocks.filter(block => selectedCarriers.some(c => c.id === block.carrierId));
      this.equipmentBlocks.forEach(block => block.array.forEach(eq => eq.selected = true));

      this.unsignedEqBlocks.forEach(block => block.array.forEach(eq => eq.selected = selectedCarriers.some(c => c.id === eq.carrierId)));
      this.sortBySelectedUnEq(selectedCarriers);
      this.unsignedEqBlocks.forEach(b => b.array.forEach(eq => eq.selected = false))
      this.getRemainingEq();
    } else {
      this.driversDisplay = 'list'
      this.drivers = structuredClone(this.allDrivers)
      this.drivers.forEach(item => item.selected = false)
      this.carriers = structuredClone(this.allCarriers)
      this.carriers.forEach(c => c.selected = false)
      this.equipmentBlocks = structuredClone(this.allEquipmentBlocks)
      this.equipmentBlocks.forEach(block => block.array.forEach(eq => eq.selected = false))
      this.remainingEqBlocks.length = 0;
      this.unsignedEqBlocks = structuredClone(this.allUnEquipmentBlocks)
      this.unsignedEqBlocks.forEach(b => b.array.forEach(eq => eq.selected = b.unnasigned = false))
    }
  }

  sortBySelectedUnEq(selectedCarriers: Array<any>) {
    const filteredUnEq = this.allUnEquipmentBlocks.filter(block => selectedCarriers.some(c => c.id === block.carrierId))

    filteredUnEq.forEach(b => {
      b.array.forEach(eq => eq.selected = true)
      b.unnasigned = true;
      this.equipmentBlocks.push(b)
    });
    this.equipmentBlocks.sort((a, b)=> {
      const nameA = a.carrierName.toLowerCase()
      const nameB = b.carrierName.toLowerCase()

      if (nameA < nameB) return -1
      if (nameA > nameB) return 1

      return 0;
    });

    this.unsignedEqBlocks = this.allUnEquipmentBlocks.filter(block => !selectedCarriers.some(c => c.id === block.carrierId));
  }

  checkCarrier(carrier: any): void {
    carrier.checked = !carrier.checked
  }

  checkCarriers(): void {
    this.carriers.forEach(carrier => carrier.checked = !this.carriersChecked)
    this.carriersChecked = !this.carriersChecked
  }

  filterCarriersByKey(): void {
    this.carriers = this.allCarriers.filter(carrier => carrier.name.toLowerCase().includes(this.searchCarrierKey.toLowerCase()))
  }

  clearCarriers(): void {
    this.carriers = structuredClone(this.allCarriers)
    // this.carriers = this.allCarriers.filter(c=> this.drivers.some(d=> d.carrierId === c.id))
    this.carriers.forEach(carrier => { carrier.selected = false; carrier.checked = false })
    this.carriersChecked = false
    this.searchCarrierKey = ''
  }

  editCarrier(carrier: any) {
    this.loaderService.show();
    this.loadsService.getCarrierById(carrier.id).then((data) => {
      data.roles = ['Carrier'];
      this.openEditCarrierPopup(data);
      this.loaderService.hide();
    });
  }

  clearAll() {
    this.clearDrivers();
    this.clearEquipment();
    this.clearCarriers();
  }

  openEditCarrierPopup(row: any) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: row }, (popup: any) => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate": this.carrierDeactivateHandler(row, componentInstance); break;
        case "ok": this.updateCarrier(popup.formData, row.id).then(() => componentInstance.close()); break;
      }
    });
  }

  openCreateCarrierPopup() {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;
    const data = {createCarrier: true, roles: ['Carrier']};
    componentInstance.subscribe({ data: data }, (popup: any) => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "ok": this.createCarrier(popup.formData).then( async ()=> {
            componentInstance.close();
            let res = await firstValueFrom(this.carriersService.getCarriers());
            this.allCarriers = this.carriers = res;
            this.loaderService.hide();
          }); 
        break;
      }
    });
  }

  carrierDeactivateHandler(row: any, parentDialogRef: any) {
    const text: string = 'Are you sure you want to deactivate ' + row.name + ' ? This action cannot be undone.'

    this.openDeleteDialog(text).then(res => {
      if (!res) return;
      this.loaderService.show();
      this.loadsService.deleteCarrier(row.id).subscribe(async () => {
        let res = await firstValueFrom(this.carriersService.getCarriers());
        this.allCarriers = this.carriers = res;
        this.loaderService.hide();
        parentDialogRef.close();
      },
        (error) => {
          this.loaderService.hide();
          console.log(error);
        });
    });
  }

  filterDriversByKey(): void {
    if (this.driversDisplay === 'list') {
      this.drivers = this.allDrivers.filter(driver => driver.driverFullName.toLowerCase().includes(this.searchDriverKey.toLowerCase()))
    } else if (this.driversDisplay === 'blocks') {
      this.driverBlocks = structuredClone(this.allDriverBlocks).map(block => {        
        block.array = block.array.filter(d => (d.driverFullName).toLowerCase().includes(this.searchDriverKey.toLowerCase()))
        return block
      }).filter(f=> f.array.length > 0);   
    } else if (this.driversDisplay === 'both') {
      this.driverBlocks = structuredClone(this.allDriverBlocks).map(block => {
        block.array = block.array.filter(d => (d.driverFullName).toLowerCase().includes(this.searchDriverKey.toLowerCase()))
        return block
      })
      
      this.drivers = this.getDriversList().filter(driver => driver.Name.toLowerCase().includes(this.searchDriverKey.toLowerCase()))
    } 
  }

  addDriver() {
    this.openCreateDriverPopup(true);
  }

  editTrailerClick(resource: any) {
    this.openEditTrailerPopup({
      data: resource,
      fuelType: this.fuelTypeSource,
      ownerType: this.ownerTypeSource,
      trailerType: this.trailerTypeSource,
      makeType: this.makeTypeSource,
      carriers: this.allCarriers
    });
  }

  editVehicleClick(resource: any) {
    this.openeditVehiclePopup({
      data: resource,
      fuelType: this.fuelTypeSource,
      makeType: this.makeTypeSource,
      carriers: this.allCarriers
    });
  }

  createVehicleClick() {
    this.openeditVehiclePopup({
      createVehicle: true,
      fuelType: this.fuelTypeSource,
      makeType: this.makeTypeSource,
      carriers: this.allCarriers
    });
    this.toggleOverlay();
  }

  createTrailerClick() {
    this.openEditTrailerPopup({
      createTrailer: true,
      fuelType: this.fuelTypeSource,
      ownerType: this.ownerTypeSource,
      trailerType: this.trailerTypeSource,
      makeType: this.makeTypeSource,
      carriers: this.allCarriers
    });
    this.toggleOverlay();
  }

  openEditTrailerPopup(resource: any) {
    const dialogRef = this.dialog1.open(EditTrailerPopupComponent);
    const componentInstance = dialogRef.componentInstance;
    componentInstance.subscribe({ data : resource }, popup => {
      switch (popup.type) {
        case "deactivate":
          const data = {trailerUnit: popup.data.data.trailerUnit, trailerId: resource.data.trailerId}
          this.deleteTrailerHandler(data, componentInstance);
          break;
        case "close": componentInstance.close(); break;
        case "ok":
          if(resource.createTrailer) {
            this.createTrailer(popup.formData).then(async () => {
              this.getData();
              componentInstance.close()
            });
          } else {
            this.updateTrailer(popup.formData, resource.data.trailerId).then(async () => {
              this.getData();
              componentInstance.close()
            });
          }
          break;
      }
    });
  }

  deleteTrailerHandler(data: any, componentInstance?: any) {
    const text: string = 'Are you sure you want to deactivate ' + data.trailerUnit + ' ? This action cannot be undone.'
    this.openDeleteDialog(text).then(res => {
      if (res) {
        this.loaderService.show();
        this.loadsService.deleteTrailer(data.trailerId).subscribe(async () => {
          this.loaderService.hide();
          if(componentInstance) componentInstance.close();
          this.getData();
        }, (error) => {
          this.loaderService.hide();
          console.log(error);
        });
      }
    })
  }

  openeditVehiclePopup(resource: any) {
    const dialogRef = this.dialog1.open(EditVehiclePopupComponent);
    const componentInstance = dialogRef.componentInstance;
    componentInstance.subscribe({ data: resource }, popup => {
      switch (popup.type) {
        case "deactivate":
          const data = {vehicleUnit: popup.data.data.vehicleUnit, vehicleId: resource.data.vehicleId}
          this.deleteVehicleHandler(data, componentInstance);
          break;
        case "close": componentInstance.close(); break;
        case "ok":
          if(resource.createVehicle) {
            this.createVehicle(popup.formData).then(async () => {
              this.getData();
              componentInstance.close()
            });
          } else {
            this.updateVehicle(popup.formData, resource.data.vehicleId).then(async () => {
              this.getData();
              componentInstance.close()
            });
          }
        break;
      }
    });
  }

  deleteVehicleHandler(data: any, componentInstance?: any) {
    const text: string = 'Are you sure you want to deactivate ' + data.vehicleUnit + ' ? This action cannot be undone.'
    this.openDeleteDialog(text).then(res => {
      if (res) {
        this.loaderService.show();
        this.loadsService.deleteVehicle(data.vehicleId).subscribe(async () => {
          this.loaderService.hide();
          if(componentInstance) componentInstance.close();
          this.getData();
        },
          (error) => {
            this.loaderService.hide();
            console.log(error);
          });
      }
    })
  }

  openCreateDriverPopup(coDriverVisible: boolean = false) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);

    const componentInstance = dialogRef.componentInstance;
    const data = { roles: [UserRolesCode.DRIVER], tempCreateDriver: true, coDriverVisible: coDriverVisible, gSearchID: 'masterDriver'};
    componentInstance.subscribe({data: data}, popup => { 
      switch(popup.type) {
        case "close": componentInstance.close(); break;
        case "addCoDriver": this.addCoDriverPopup(false, popup.formData, componentInstance); break;
        case "ok": 
        this.loaderService.show();
        this.createDriver(popup.formData).then(()=> {
          this.loaderService.hide();
          this.notificationService.success("Succes", 'Driver was added succesfully!');
          this.getData();
          componentInstance.close();
        }, err =>{
          this.loaderService.hide();
        });
        break;
      }
    });
  }

  addCoDriverPopup(coDriverVisible: boolean, masterDriverData: any, masterPopupInstance: any, masterDriverID?: number) {    
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;
    const data = { roles: [UserRolesCode.DRIVER], tempCreateDriver: true, coDriverVisible: coDriverVisible, masterDriverData: masterDriverData, gSearchID: 'coDriver' };
    componentInstance.subscribe({data: data}, popup => { 
      switch(popup.type) {
        case "close": componentInstance.close(); break;
        case "ok": 
        masterDriverData.coDriver = popup.formData;
        if(masterDriverID) {
          this.updateDriver(masterDriverData, masterDriverID).then(()=> {
            this.notificationService.success("Succes", String.raw`Driver \ CoDriver was updated succesfully!`);
            this.loaderService.hide();
            componentInstance.close();
            masterPopupInstance.close();
            this.getData();
          }, err =>{
            console.log(err);
            this.loaderService.hide();
          });
        } else {
          this.loaderService.show();
          this.createDriver(masterDriverData).then(()=> {
            this.notificationService.success("Succes", String.raw`Driver \ CoDriver was added succesfully!`);
            this.loaderService.hide();
            componentInstance.close();
            masterPopupInstance.close();
            this.getData();
          }, err =>{
            console.log(err);
            this.loaderService.hide();
          });
        }
        break;
      }
    });
  }

  async editDriverClick(driver: any) {
    let result:any;
    result = await this.loadsService.getDriverById(driver.id);
    result.roles = [UserRolesCode.DRIVER];
    result = {...result, gSearchID: 'masterDriver', coDriverVisible: true};
    this.openEditMainDriverPopup(result);
  }

  openEditMainDriverPopup(userData: any) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({data: userData}, popup => { 
      switch(popup.type) {
        case "close" : componentInstance.close(); break;
        case "deactivate": 
          const text:string = 'Are you sure you want to deactivate '+userData.firstName+' '+userData.lastName+' ? This action cannot be undone.'
          this.driverDeactivateHandler(text, userData, componentInstance);
        break;
        case "ok": this.driverConfirmHandler(popup.formData, userData.id, componentInstance); break;
        case "editCodriver": 
          popup.formData.id = userData.id;
          this.editCoDriverPopup(popup.formData, userData.coDriverId, componentInstance); 
        break;

        case "addCoDriver": this.addCoDriverPopup(false, popup.formData, componentInstance, userData.id); break;
      }
    });
  }

  
  async editCoDriverPopup(mainDriver: any, codriverID: number, masterPopupInstance: any) {
    let coDriverData = await this.loadsService.getDriverById(codriverID);
    const masterDriverData = {
      trailerId: mainDriver.trailerId,
      vehicleId: mainDriver.vehicleId,
      dispatcherId: mainDriver.dispatcherId,
      carrierId: mainDriver.carrierId,
      newTrailerUnit: mainDriver.newTrailerUnit || null,
      newVehicleUnit: mainDriver.newTrailerUnit || null,
      newCarrierName: mainDriver.newTrailerUnit || null,
    }
    coDriverData = {...coDriverData, gSearchID: 'coDriver', coDriverVisible: false, masterDriverData: masterDriverData, roles: [UserRolesCode.DRIVER]};
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;
    
    let data: any = {data: coDriverData};

    componentInstance.subscribe(data, popup => { 
      switch(popup.type) {
        case "close" : componentInstance.close(); break;
        case "deactivate": 
          const text:string = 'Are you sure you want to deactivate '+popup.formData.firstName+' '+popup.formData.lastName+' ? This action cannot be undone.'
          masterPopupInstance.driverFormComp.row.coDriverId = null;
          this.driverDeactivateHandler(text, popup.formData).then(res=>{
            if(res) {
              masterPopupInstance.driverFormComp.row.coDriverId = null;
              componentInstance.close();
            }
          });
        break;
        case "ok": 
          mainDriver.coDriver = popup.formData;
          mainDriver.coDriver.id = codriverID;
          mainDriver.roles = [UserRolesCode.DRIVER];
          this.driverConfirmHandler(mainDriver, mainDriver.id, masterPopupInstance, componentInstance); 
        break;
      }
    });
  }

  driverDeactivateHandler(text:string, row: any, parentDialogRef?: any): Promise<boolean> {
    return new Promise<any>((resolve) => {
      this.openDeleteDialog(text).then(res=>{
        if(!res) return;

        switch (row.roles[0]) {
          case 'Driver':
          this.loaderService.show();
          this.usersService.deleteDriver(row.id).subscribe(()=>{
            resolve(true);
            if(parentDialogRef) parentDialogRef.close();
              this.getData();
              this.clearAll();
              this.loaderService.hide();
            }, error =>{
              this.loaderService.hide();
              console.log(error);
            });
          break;

          default:
            this.loaderService.show();
            this.usersPageService.deleteUser(row.id).subscribe(()=>{
              if(parentDialogRef) parentDialogRef.close();
              this.getData();
              this.loaderService.hide();
            }, error =>{
              this.loaderService.hide();
              console.log(error);
            });
          break;
        }
      });
    });
  }

  driverConfirmHandler(masterDriverForm: any, driverID: number,masterPopupInstance: any, detailPopupInstance?: any) {
    this.updateDriver(masterDriverForm, driverID).then(()=> {
      if(masterDriverForm.coDriver) this.notificationService.success("Succes", String.raw`Driver \ CoDriver was updated succesfully!`);
      else this.notificationService.success("Succes", `Driver was updated succesfully!`);
      masterPopupInstance.close();
      if(detailPopupInstance) detailPopupInstance.close();
      this.getData();
     });
  }

  
  openDeleteDialog(deleteText: string):Promise<boolean> {
    return new Promise<any>((resolve, reject)=>{
      const deletIconStyle = {stroke: '#D92D20', width: '48px', heigth: '48px'};
      const btn1Style = {background: 'white', color: '#344054', border: '1px solid #D0D5DD'};
      const btn2Style = {background: '#D92D20', color: 'white', border: '1px solid #D92D20'};

      const dialogData: any = {
        title: 'Delete',
        text: deleteText,
        button1Text: 'Cancel',
        button2Text: 'Delete',
        icon: 'custom-trash',
        iconStyle: deletIconStyle,
        dsa: true
      };

      const dialogRef = this.dialog.openDialog(DialogComponent, {
        props: {...dialogData, btn1Style, btn2Style}
      });

      dialogRef.subscribe(dialog => {
        if(!dialog) return;
        switch (dialog.result) {
          case 'button1': resolve(false); break;
          case 'button2': resolve(true); break;
        }
      });
    });
  }

  deleteDriverClick (driver: any) {
    driver.roles = [UserRolesCode.DRIVER];
    const text:string = 'Are you sure you want to deactivate '+driver.driverFullName+' ? This action cannot be undone.'
    this.driverDeactivateHandler(text, driver);
  }

  clearDrivers(): void {
    this.driverBlocks = []
    this.driversDisplay = 'list'
    this.drivers = structuredClone(this.allDrivers)
    this.drivers.forEach(driver => { driver.selected = false; driver.checked = false })
    this.driversChecked = false
    this.driverBlocks = []
    this.searchDriverKey = ''
  }

  checkDrivers(): void {
    this.drivers.forEach(driver => driver.checked = !this.driversChecked)
    this.driversChecked = !this.driversChecked
  }

  selectDriver(driver: any): void {
    driver.selected = !driver.selected

    let selectedDrivers: any[] = []

    if (this.driversDisplay === 'list') {
      selectedDrivers = this.drivers.filter(driver => driver.selected)
    } else if (this.driversDisplay === 'both') {
      const a = structuredClone(this.driverBlocks);
      const blockedDriversIds = a.flatMap(block => block.array).map(driver => driver.id);
      let InBlockDrivers:any = this.allDrivers.filter(driver => blockedDriversIds.includes(driver.id));
      InBlockDrivers.find((f:any)=> f.id !== driver.id) ? 
      selectedDrivers = [...InBlockDrivers,driver] :
      selectedDrivers = [...InBlockDrivers];
    }

    this.carriers = this.allCarriers.map(c => {
      return {
        ...c,
        selected: selectedDrivers.some(d => d.carrierId === c.id)
      };
    }); 
    
    switch (selectedDrivers.length) {
      case 0:
        this.carriers = structuredClone(this.allCarriers)
        this.drivers = structuredClone(this.allDrivers)
        this.equipmentBlocks = structuredClone(this.allEquipmentBlocks);
        [...this.carriers, ...this.drivers].forEach(item => item.selected = false)
        this.equipmentBlocks.forEach(block => block.array.forEach(eq => eq.selected = false))
      break;

      case 1:
        this.driversDisplay === 'list';
          this.drivers = structuredClone(this.allDrivers).map(d => {
            d.selected = selectedDrivers.some(selected => selected.id === d.id)
            return d
          })
          this.equipmentBlocks = structuredClone(this.allEquipmentBlocks).filter(b => this.carriers.some(c => c.id === b.carrierId))
          this.equipmentBlocks = this.equipmentBlocks.map(b => {
            b.array = b.array.filter(eq => selectedDrivers.some(d => d.id === eq.driverId))
            b.array.forEach(eq => eq.selected = true)
            return b
          }).filter(f=> f.array.length > 0);
          this.remainingEqBlocks.length = 0;
        break;
    
      default:
        this.driversDisplay = 'both';
        this.driverBlocks = this.getDriverBlocks(this.carriers.filter(f=>f.selected), selectedDrivers)
        this.driverBlocks.forEach(block => block.array.forEach(driver=> driver.selected = true))
        this.equipmentBlocks = structuredClone(this.allEquipmentBlocks).filter(b => this.carriers.some(c => c.id === b.carrierId))
        this.equipmentBlocks = this.equipmentBlocks.map(b => {
          b.array = b.array.filter(eq => selectedDrivers.some(d => d.id === eq.driverId))
          b.array.forEach(eq => eq.selected = true)
          return b
        }).filter(f=> f.array.length > 0);
        this.getRemainingDriver();     
        this.remainingEqBlocks = structuredClone(this.allEquipmentBlocks).filter(b => this.carriers.some(c => c.id === b.carrierId));
        this.remainingEqBlocks = this.remainingEqBlocks.map(b => {
          b.array = b.array.filter(eq => this.remainingDrivers.some(d => d.id === eq.driverId))
          b.array.forEach(eq => eq.selected = false)
          return b
        }).filter(f=> f.array.length > 0);
      break;
    }
  }

  deSelectDriver(driver: any) {
    this.driverBlocks = this.deleteObjectFromArray(this.driverBlocks, driver).filter(f=> f.array.length > 0);

    if(this.driverBlocks.flatMap(block => block.array).length === 0) {
      this.drivers = structuredClone(this.allDrivers);
      this.driversDisplay = 'list';
      this.equipmentBlocks.length = this.remainingEqBlocks.length = 0;
      this.clearAll();
    } else {
      this.remainingDrivers.push(driver);
      this.remainingDrivers.forEach(d=> d.selected = false);
      let remainingDriversFromBlocks = this.driverBlocks.flatMap(block => block.array).filter(f=> f.id !== driver.id);  
      let selectedDrivers: Array<any> = new Array<any>();
      if(this.driversDisplay === 'list') selectedDrivers = this.drivers.filter(f=> f.selected);
      if(this.driversDisplay === 'both') selectedDrivers = this.allDrivers.filter(f => remainingDriversFromBlocks.some(s => f.id === s.id));
      this.carriers = this.allCarriers.map(c => {
        return {
          ...c,
          selected: selectedDrivers.some(d => d.carrierId === c.id)
        };
      });
      this.moveToRemaing(driver);
      this.equipmentBlocks = this.equipmentBlocks.map(b => {
        b.array = b.array.filter(eq => selectedDrivers.some(d => d.id == eq.driverId))
        b.array.forEach(eq => eq.selected = true)
        return b
      }).filter(f=> f.array.length > 0);
      this.getRemainingDriver();
    }
  }

  moveToRemaing(driver: any) {
    const carrierId = driver.carrierId;
    const carrierName = driver.carrierName;
  
    let carrierBlock = this.remainingEqBlocks.find(block => block.carrierId === carrierId);
  
    if (carrierBlock) {
      carrierBlock.array.push({
        driverId: driver.id,
        driverName: driver.driverFullName,
        carrierId: driver.carrierId,
        carrierName: driver.carrierName,
        trailerId: driver.trailerId,
        trailerUnit: driver.trailerUnit,
        vehicleId: driver.vehicleId,
        vehicleUnit: driver.vehicleUnit,
        trailerChecked: false,
        vehicleChecked: false,
        selected: false,
        checked: false,
      });
    } else {
      const newBlock = {
        carrierId: carrierId,
        carrierName: carrierName,
        checked: false,
        array: [
          {
            driverId: driver.id,
            driverName: driver.driverFullName,
            carrierId: driver.carrierId,
            carrierName: driver.carrierName,
            trailerId: driver.trailerId,
            trailerUnit: driver.trailerUnit,
            vehicleId: driver.vehicleId,
            vehicleUnit: driver.vehicleUnit,
            trailerChecked: false,
            vehicleChecked: false,
            selected: false,
            checked: false,
          },
        ],
      };
      this.remainingEqBlocks.push(newBlock);
    }
  }

  getRemainingDriver() {
    const blockedDriverIds = this.driverBlocks.flatMap(block => block.array).map(driver => driver.id);
    this.remainingDrivers = this.allDrivers.filter(driver => !blockedDriverIds.includes(driver.id));
    this.remainingDrivers.forEach(f=>f.selected = false);
  }

  getRemainingEq() {
    const selectedCarriers = this.carriers.filter(f=>!f.selected);
    this.remainingEqBlocks = this.allEquipmentBlocks.filter(block => selectedCarriers.some((c:any) => c.id === block.carrierId))
    this.remainingEqBlocks.forEach(b => b.array.forEach(eq => eq.selected = false))
  }

  deleteObjectFromArray(arr: any[], objToRemove: any): any[] {
    arr.forEach(item => item.array = item.array.filter((item:any) => item.id !== objToRemove.id));
    return arr;
  }

  checkDriver(driver: any): void {
    driver.checked = !driver.checked
  }

  selectEquipment(eq: Equipment): void {
    eq.selected = !eq.selected

    const allEquipment = this.equipmentBlocks.flatMap(block => block.array)
    const selectedEquipment = allEquipment.filter(eq => eq.selected)
    let unEq: any = this.unsignedEqBlocks.flatMap(block => block.array).filter(f=>f.selected);

    switch (selectedEquipment.length) {
      case 0:
        this.equipmentBlocks = structuredClone(this.allEquipmentBlocks)
        this.equipmentBlocks.forEach(block => block.array.forEach(eq => eq.selected = false))
        this.driverBlocks = []
        this.driversDisplay = 'list'
        this.drivers = structuredClone(this.allDrivers)
        this.drivers.forEach(d => d.selected = false)
        this.carriers = structuredClone(this.allCarriers)
        this.carriers.forEach(c => c.selected = false)

        //Additionally check unEq for carriers
        if(unEq.length > 0) {
          this.carriers = this.allCarriers.map(c => {
            return {
              ...c,
              selected: unEq.some((d:any) => d.carrierId === c.id)
            };
          });

          if(selectedEquipment.length === 0)  {
            this.drivers.length = 0;
          } else {
            this.drivers = this.allDrivers.filter(d => selectedEquipment.some(eq => eq.driverId === d.id))
          }
        }

        break;
      case 1:
        this.driversDisplay = 'list'
        this.driverBlocks = []
        this.drivers.forEach(d => d.selected = selectedEquipment.some(eq => eq.driverId === d.id));
        const selectedDrivers = this.drivers.filter(d => selectedEquipment.some(eq => eq.driverId === d.id))
        this.carriers = this.allCarriers.map(c => {
          return {
            ...c,
            selected: selectedDrivers.some((d:any) => d.carrierId === c.id) || unEq.some((d:any) => d.carrierId === c.id)
          };
        });
        if(selectedEquipment.length > 0) {
          const selDriver = this.allDrivers.find(d => selectedEquipment.some(eq => eq.driverId === d.id))
          this.drivers = this.allDrivers.map(c => {
            return {
              ...c,
              selected: selDriver.id === c.id
            };
          });
        }
      break;
    
      default:
        this.driversDisplay = 'both'
        this.driverBlocks = this.getDriverBlocks(this.carriers, structuredClone(this.allDrivers))
        this.driverBlocks = this.driverBlocks.map(block => {
          block.array = block.array.filter(d => selectedEquipment.some(eq => eq.driverId === d.id))
          block.array.forEach(eq => eq.selected = true)
          return block
        }).filter(f=> f.array.length > 0);
        this.getRemainingDriver();
        let selectedDriverss = this.driverBlocks.flatMap(block => block.array).filter(f=>f.selected)
        this.carriers = this.allCarriers.map(c => {
          return {
            ...c,
            selected: selectedDriverss.some((d:any) => d.carrierId === c.id)
          };
        });

        if(unEq.length > 0) {
          this.carriers = this.allCarriers.map(c => {
            return {
              ...c,
              selected: selectedDriverss.some((d:any) => d.carrierId === c.id) || unEq.some((d:any) => d.carrierId === c.id)
            };
          });
        }

      break;
    }
  }

  private getDriverBlocks(carriers: any[], drivers: any[]): DriverBlock[] {
    const blocks: DriverBlock[] = []
    carriers.forEach(carrier => {
      if (drivers.some(driver => driver.carrierId === carrier.id)) {
        blocks.push({
          carrierId: carrier.id,
          carrierName: carrier.name,
          array: []
        })

        drivers.forEach(driver => {
          if (driver.carrierId === carrier.id) {
            const block = blocks.find(block => block.carrierId === driver.carrierId)
            
            if (block) {
              block.array.push(driver)
            }
          }
        })

        blocks.forEach(block => {
          carriers.forEach(carrier => {
            if (block.carrierId === carrier.id) {
              block.carrierId = carrier.id
              block.carrierName = carrier.name
            }
          })
        })
      }
    })

    return blocks
  }

  private getDriversList(): any[] {
    const blockedDrivers = this.driverBlocks.flatMap(block => block.array)
    const drivers = this.allDrivers.filter(driver => !blockedDrivers.some(item => driver.DriverId === item.DriverId))
    return drivers
  }

  private getEquipmentBlocks(equipment: Equipment[]): EquipmentBlock[] {
    const blocks: EquipmentBlock[] = []
    structuredClone(equipment).forEach(eq => {
      if (!blocks.some(block => block.carrierId === eq.carrierId)) {
        blocks.push({
          carrierId: eq.carrierId,
          carrierName: eq.carrierName,
          checked: false,
          array: []
        })
      }

      const block = blocks.find(block => block.carrierId === eq.carrierId)

      if (block) {
        block.array.push({
          driverId: eq.driverId,
          driverName: eq.driverName,
          carrierId: eq.carrierId,
          carrierName: eq.carrierName,
          trailerId: eq.trailerId,
          trailerUnit: eq.trailerUnit,
          vehicleUnit: eq.vehicleUnit,
          vehicleId: eq.vehicleId,
          trailerChecked: false,
          vehicleChecked: false,
          selected: false,
          checked: eq.checked
        })
      }
    })
    return blocks
  }

  checkEquipmentBlock(block: EquipmentBlock) : void {
    block.checked = !block.checked
    block.array.forEach(eq => { eq.trailerChecked = block.checked; eq.vehicleChecked = block.checked })
  }

  clearEquipment(): void {
    this.equipmentBlocks = structuredClone(this.allEquipmentBlocks)
    this.equipmentBlocks.forEach(block => block.array.forEach(eq => { eq.selected = false; eq.trailerChecked = false; eq.vehicleChecked = false }))
    this.unsignedEqBlocks.forEach(b => b.array.forEach(eq => eq.selected = false))
    this.remainingEqBlocks.length = 0;
    this.equipmentChecked = false
    this.searchEqKey = ''
  }

  checkEqTrailer(block: EquipmentBlock, eq: Equipment): void {
    eq.trailerChecked = !eq.trailerChecked
    block.checked = block.array.every(equip => equip.trailerChecked && equip.vehicleChecked)
  }

  checkEqVehicle(block: EquipmentBlock, eq: Equipment): void {
    eq.vehicleChecked = !eq.vehicleChecked
    block.checked = block.array.every(equip => equip.trailerChecked && equip.vehicleChecked)
  }

  searchEqByKey(): void {
    this.equipmentBlocks = structuredClone(this.allEquipmentBlocks).map(block => {
      block.array = block.array.filter(eq => {
        return (eq.carrierName + eq.driverName + eq.trailerUnit + eq.vehicleUnit).toLowerCase().includes(this.searchEqKey.toLowerCase())
      })
      return block
    }).filter(f=> f.array.length > 0);
  }

  isCarrierSearchDisabled(): boolean {
    return this.carriers.filter(f=>f.selected).length > 0;
  }

  isEqSearchDisabled(): boolean {
    return this.equipmentBlocks.flatMap(block => block.array).filter(f=>f.selected).length > 0 || this.equipmentBlocks.flatMap(block => block.array).length === 0;
  }

  isDriversSearchDisabled(): boolean {
    if(this.driversDisplay === 'list') return this.drivers.filter(f=>f.selected).length > 0 || this.drivers.length === 0;
    else return this.driverBlocks.flatMap(block => block.array).filter(f=>f.selected).length > 0 || this.driverBlocks.flatMap(block => block.array).length === 0;
  }

  async recycleBin() {
    let response: any;
    const dialogRef = this.dialog1.open(RecycleBinComponent);
    const componentInstance = dialogRef.componentInstance;
    const columns = [
      { field: 'equipmentUnit', header: 'Equipment Unit' },
      { field: 'equipmentType', header: 'Equipment Type' },
      { field: 'deletedBy', header: 'Who Deleted' },
      { field: 'deletedDate', header: 'Date & Time' },
    ];

    try {
      this.loaderService.show();
      response = await firstValueFrom(this.vehiclesService.getDeletedEquipment());
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
    }

    if(!response) return;
    
    const data = response.map((equipment:any) => ({
      ...equipment,
      deletedDate: moment(equipment.deletedDate, "YYYY-MM-DD HH:mm:ss.S").format("D MMMM YYYY, hh:mm:ss A")
    }));
    data.columns = columns;
    data.title = 'Deactivated Equipment';

    componentInstance.subscribe(data, (popup:any) => { 
      switch(popup.type) {
        case "close" : componentInstance.close(); break;
        case "restore" : 
          this.loaderService.show();
          this.vehiclesService.restoreEquipment(popup.data).subscribe(data=> {
            componentInstance.close();
            this.getData();
          },(error) => {
            this.loaderService.hide();
            console.error(error);
          }); 
        break;
      }
    });
  }

  onDuplicate(): void {
    const allCheckedDrivers = this.getAllCheckedDrivers()

    if (allCheckedDrivers.length) {
      this.dialog.openDialog(DuplicateDriversComponent, {
        carriers: structuredClone(this.allCarriers),
        checkedDrivers: structuredClone(allCheckedDrivers)
      }).subscribe(result => {
        if (result) {
          this.dialog.openDialog(DuplicatedDriversComponent, {
            carriers: result.carriers,
            drivers: structuredClone(allCheckedDrivers)
          });
        }
      });
    }
  }

  onReassign(): void {
    const allCheckedTrailers = this.getAllCheckedTrailers()
    const allCheckedVehicles = this.getAllCheckedVehicles()
    const allCheckedDrivers = this.getAllCheckedDrivers()

    if (allCheckedTrailers.length || allCheckedVehicles.length) {
      this.dialog.openDialog(ReassignEquipmentComponent, {
        carriers: structuredClone(this.allCarriers),
        drivers: structuredClone(this.allDrivers),
        trailers: structuredClone(allCheckedTrailers),
        vehicles: structuredClone(allCheckedVehicles)
      }).subscribe(result => {
        if (result) {
          this.dialog.openDialog(ReassignedEquipmentComponent, {
            carrier: result.carrier,
            driver: result.driver,
            trailers: result.trailers,
            vehicles: result.vehicles
          });
        }
      });
    } else if (allCheckedDrivers.length === 1) {
      this.dialog.openDialog(ReassignDriverComponent, {
        carriers: structuredClone(this.allCarriers),
        driver: structuredClone(allCheckedDrivers[0])
      }).subscribe(result => {
        if (result) {
          this.dialog.openDialog(ReassignedDriverComponent, {
            carrier: result.carrier,
            driver: result.driver
          });
        }
      })
    }
  }

  private getAllCheckedTrailers(): any[] {
    const trailers = this.equipmentBlocks.flatMap(block => block.array)
    const checked = trailers.filter(item => item.trailerChecked)
    return checked
  }

  private getAllCheckedVehicles(): any[] {
    const vehicles = this.equipmentBlocks.flatMap(block => block.array)
    const checked = vehicles.filter(item => item.vehicleChecked)
    return checked
  }

  private getAllCheckedDrivers(): any[] {
    let drivers: any[] = []

    if (this.driversDisplay === 'list') {
      drivers = this.drivers.filter(d => d.checked)
    } else if (this.driversDisplay === 'blocks') {
      const items = this.driverBlocks.flatMap(block => block.array)
      drivers = items.filter(d => d.checked)
    } else if (this.driversDisplay === 'both') {
      const fromList = this.drivers.filter(d => d.checked)
      const fromBlocks = this.driverBlocks.flatMap(block => block.array)
      drivers = fromList.concat(fromBlocks)
    }
    return drivers
  }

  
  updateCarrier(data: Array<any>, carrierID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.updateCarrier(data, carrierID).subscribe(
        async (data) => {
          let res = await firstValueFrom(this.carriersService.getCarriers());
          this.allCarriers = this.carriers = res;
          this.loaderService.hide();
          resolve(data);
        },
        (error) => {
          console.log(error);
          this.loaderService.hide();
          reject(error);
          // this.openWarningDialog(error.error.message);
        }
      );
    });
  }
  
  updateDriver(data: Array<any>, driverID: number):Promise<any> {
    return new Promise<any>((resolve, reject)=>{
      this.loaderService.show();
      this.loadsService.updateDriver(data, driverID).subscribe(async (res) => {
        this.allDrivers = this.drivers = await firstValueFrom(this.vehiclesService.getDrivers())
        this.loaderService.hide();
        resolve(res);
        },(error) => {
          console.log(error);
          this.loaderService.hide();
          reject(error);
        }
      );
    });
  }

  createCarrier(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.createCarrier(data).subscribe(res => {
        this.loaderService.hide();
        resolve(data);
      }, error => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      })
    });
  }

  createDriver(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.createDriver(data).subscribe(res => {
        this.loaderService.hide();
        resolve(data);
      }, error => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      })
    });
  }

  updateTrailer(data: Array<any>, trailerID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.updateTrailer(data, trailerID).subscribe((res) => {
        this.loaderService.hide();
        resolve(res);
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      });
    });
  }

  createTrailer(data: Array<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.createTrailer(data).subscribe((res) => {
        this.loaderService.hide();
        resolve(res);
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      });
    });
  }

  updateVehicle(data: Array<any>, trailerID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.updateVehicle(data, trailerID).subscribe((res) => {
        this.loaderService.hide();
        resolve(res);
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      });
    });
  }

  createVehicle(data: Array<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.createVehicle(data).subscribe((res) => {
        this.loaderService.hide();
        resolve(res);
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      });
    });
  }
}
